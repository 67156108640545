<div class="page-title-area-student">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text noob">
                    <h2>Log In</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="login-area pt-100 close" style="padding-top:40px; padding-bottom :60px">
    <div class="container">
        <div class="containers" [ngClass]="dynoclass ? 'right-panel-active' : '' " id="container" style="z-index: 0;">
            <div class="form-container sign-up-container">
                <div class="wrapper"
                    style="position:relative; height:540px; width: 100%; overflow-x:hidden; overflow-y:scroll;">
                    <div class="job-card" style="color: #b3b9ba;">
                        <div class="job-inner align-items-center">
                            <div class="row">
                                <div class="col-12 col-lg-10 mx-auto">
                                    <div class="text-start">
                                        <!-- <img src="../../../../assets/img/home-1/1.png" width="180" alt=""> -->
                                    </div>
                                </div>
                            </div>
                            <div class="login-sign-in">
                                <br><br><br>
                                <h3>
                                    Enter your Details in below Register Form
                                </h3>
                                <br><br>
                                <i style="color: #37e15e;font-size: 37px" (click)="scroll(div)"
                                    class="fa fa-arrow-circle-down" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-container sign-in-container">
                <div *ngIf="!dynoclass">
                    <div class="wrapper">
                        <div class="authentication-reset-password d-flex align-items-center justify-content-center">
                            <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s">
                                <div class="job-card" style="color: #b3b9ba;">
                                    <div class="job-inner align-items-center">
                                        <div class="row">
                                            <div class="col-12 col-lg-10 mx-auto">
                                                <!-- <div class="text-start">
                                                    <img src="assets/images/Logo (5).png" width="180" alt="">
                                                </div> -->
                                                <h5 class="font-weight-bold">Login as Job seeker With OTP</h5>
                                                <form [formGroup]="loginstudentForm" (ngSubmit)="studentSubmit()">
                                                    <div class="mb-3" *ngIf="!otpget">

                                                        <div class="position-relative has-icon-left">
                                                            <div id="recaptcha-container"></div>
                                                            <div id="show_hide_password">
                                                                <mat-form-field>
                                                                    <ngx-mat-intl-tel-input class="form-control"
                                                                        formControlName="phone"
                                                                        class="form-control form-control-custom"
                                                                        [enablePlaceholder]=" true "
                                                                        [enableSearch]=" true" name="phone" #phone
                                                                        [preferredCountries]="['in']"
                                                                        describedBy="phoneInput">
                                                                    </ngx-mat-intl-tel-input>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mb-3" *ngIf="otpget">
                                                        <div class="position-relative has-icon-left">
                                                            <label>OTP</label>
                                                            <div class="input-group">
                                                                <input type="text" class="form-control border-end-0"
                                                                    formControlName="usr_pwd" id="password"
                                                                    placeholder="Enter OTP"
                                                                    (keypress)="numericOnly($event)"
                                                                    (paste)="$event.preventDefault()"
                                                                    [ngClass]="{ 'is-invalid': submittedstudent && f1['usr_pwd'].errors }" />
                                                                <span class="input-group-text bg-transparent">
                                                                    <span class="material-icons">lock</span>
                                                                </span>
                                                            </div>
                                                            <div *ngIf="submittedstudent && f1['usr_pwd'].errors"
                                                                class="invalid-feedback">
                                                                <span *ngIf="f1['usr_pwd'].errors.required">Please Enter
                                                                    OTP</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="cust-resend" *ngIf="otpget">
                                                        <div class="icheck-material-primary" (click)="getOtpstudent()">

                                                            <span class="resend" *ngIf="refresh">
                                                                <span class="material-icons cust-icon"> replay_30
                                                                </span>
                                                                <span>Resend</span>
                                                            </span>
                                                            <span *ngIf="(timer$ | async) as timer">Re Send OTP in
                                                                <span>{{ timer }} Sec </span> </span>
                                                        </div>
                                                        <div class="icheck-material-primary"
                                                            (click)="studentResetpassword2()">
                                                            <a href="JavaScript:;">Wrong Number</a>
                                                        </div>
                                                    </div>
                                                    <div class=" gap-2" style="text-align: center;">
                                                        <button *ngIf="!otpget" type="button" (click)="getOtpstudent()"
                                                            class="btn btn-primary btn-block waves-effect waves-light  log-btn curse"
                                                            [disabled]="submittedstudent">
                                                            Request OTP </button>
                                                        <button *ngIf="otpget" type="submit"
                                                            class="btn btn-primary btn-block waves-effect waves-light  log-btn curse">
                                                            Submit OTP </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="login-sign-in">
                                            <ul>
                                                <li>Don’t Have Account ?</li>
                                                <li><a (click)="dynoclass = true;studentResetpassword2();openform(2);scroll(div); "
                                                        id="signUp" class="log-btn curse">Sign Up</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overlay-container">
                <div class="overlay">
                    <div class="overlay-panel overlay-left">
                        <h1>Welcome Back!</h1>
                        <p>Already have an account?</p>
                        <p>Please Login here </p>
                        <button type="button" class="ghost" (click)="dynoclass = false; onResetpassword2();openform(1) "
                            id="signIn">Sign In</button>
                    </div>
                    <div class="overlay-panel overlay-right">
                        <h1>Welcome Back!</h1>
                        <p>Are you new here?</p>
                        <p>Please Sign up as a Job Seeker</p>
                        <button type="button" class="ghost"
                            (click)="dynoclass = true;studentResetpassword2();openform(2);scroll(div); "
                            id="signUp">Sign Up</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div #div>
    <div class="login-area close" style="padding-top:40px; padding-bottom :60px" *ngIf="regform">
        <div class="container">
            <div class="containers">
                <div class="wrapper">
                    <div class="job-card" style="color: #b3b9ba;">
                        <div class="login-area pt-100 close" style="padding-top:40px; padding-bottom :60px">
                            <div class="container">
                                <h3 class="heder">Register Form</h3>
                                <form (ngSubmit)="candidateSignupPost()" [formGroup]="candidateSignup">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <label>Full Name <span style="color: red;"> *</span></label>
                                            <input type="text" class="form-control form-control-custom"
                                                id="validationCustom05" formControlName="name"
                                                [ngClass]="{'is-invalid': submitted && addUsersFormCnt['name'].errors}">
                                            <div *ngIf="submitted && addUsersFormCnt['name'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt['name'].errors.required">Please Enter
                                                    Your Name </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <label>Email<span style="color: red;"> *</span></label>
                                            <input type="email" class="form-control form-control-custom"
                                                id="validationCustom05" formControlName="email"
                                                [ngClass]="{'is-invalid': submitted && addUsersFormCnt['email'].errors}">
                                            <div *ngIf="submitted && addUsersFormCnt['email'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt['email'].errors.required">Please Enter
                                                    Your Email </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <label>Date of Birth <span style="color: red;"> *</span></label>
                                            <input type="date" class="form-control form-control-custom"
                                                id="validationCustom05" formControlName="date_of_birth"
                                                [ngClass]="{'is-invalid': submitted && addUsersFormCnt['date_of_birth'].errors}">
                                            <div *ngIf="submitted && addUsersFormCnt['date_of_birth'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt['date_of_birth'].errors.required">Please
                                                    Enter Your Date of Birth </span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4">
                                            <label>Phone Number <span style="color: red;"> *</span></label>
                                            <mat-form-field class="custom-mat-field">
                                                <ngx-mat-intl-tel-input formControlName="phone"
                                                    [enablePlaceholder]="true" [enableSearch]="true" name="phone"
                                                    #phoneInput [preferredCountries]="['in']"
                                                    class="form-control form-control-custom">
                                                </ngx-mat-intl-tel-input>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-lg-4">
                                            <label>Job Title <span style="color: red;"> *</span></label>
                                            <br><br>
                                            <select class="form-control form-control-custom" style="width:100%"
                                                formControlName="job_title"
                                                [ngClass]="{'is-invalid': submitted && addUsersFormCnt['job_title'].errors}">
                                                <option value="" disabled selected>Choose Job Title</option>
                                                <option *ngFor="let p of getDepartment" [value]="p.department_name">
                                                    {{p.department_name}}
                                                </option>
                                            </select>

                                            <div *ngIf="submitted && addUsersFormCnt['job_title'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt['job_title'].errors.required">Please
                                                    Enter Your Job Title </span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4">
                                            <label>Enter Qualification <span style="color: red;"> *</span></label>
                                            <input type="text" class="form-control form-control-custom"
                                                id="validationCustom05" formControlName="qulafication"
                                                [ngClass]="{'is-invalid': submitted && addUsersFormCnt['qulafication'].errors}">
                                            <div *ngIf="submitted && addUsersFormCnt['qulafication'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt['qulafication'].errors.required">Please
                                                    Enter Your Qualification </span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4">
                                            <label>Year of Passing <span style="color: red;"> *</span></label>
                                            <input type="text" class="form-control form-control-custom"
                                                id="validationCustom05" formControlName="year_of_passout"
                                                [ngClass]="{'is-invalid': submitted && addUsersFormCnt['year_of_passout'].errors}"
                                                (keypress)="numericOnly($event)" minlength="4" maxlength="4">
                                            <div *ngIf="submitted && addUsersFormCnt['year_of_passout'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt['year_of_passout'].errors.required">Please
                                                    Enter Your year of passing </span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4">
                                            <label>Percentage <span style="color: red;"> *</span></label>
                                            <input type="text" class="form-control form-control-custom"
                                                id="validationCustom05" formControlName="percentage"
                                                [ngClass]="{'is-invalid': submitted && addUsersFormCnt['percentage'].errors}">
                                            <div *ngIf="submitted && addUsersFormCnt['percentage'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt['percentage'].errors.required">Please
                                                    Enter Your percentage </span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4">
                                            <label>Total Experience <span style="color: red;"> *</span></label>
                                            <input type="text" class="form-control form-control-custom"
                                                id="validationCustom05" formControlName="experience"
                                                (keypress)="numericOnly($event)"
                                                [ngClass]="{'is-invalid': submitted && addUsersFormCnt['experience'].errors}">
                                            <div *ngIf="submitted && addUsersFormCnt['experience'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt['experience'].errors.required">Please
                                                    Enter Your Experience </span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4">
                                            <label>Preferred Industry <span style="color: red;"> *</span></label>
                                            <ng-select class="select custom"
                                                style=" border: 1px solid #1cc91f;border-radius: 20px;"
                                                [multiple]='true' [searchable]="true" formControlName="industry"
                                                [clearable]="true"
                                                [ngClass]="{'is-invalid': submitted && addUsersFormCnt['industry'].errors}">
                                                <ng-option [value]="item.department_name"
                                                    *ngFor="let item of industry; let int = index">
                                                    {{item.department_name}}
                                                </ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && addUsersFormCnt['industry'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt['industry'].errors.required">Please
                                                    Select Preferred Industry</span>
                                            </div>
                                        </div>

                                        <div class="col-lg-4">
                                            <label>Preferred Department/Function <span style="color: red;">
                                                    *</span></label>
                                            <ng-select class="select custom"
                                                style="border: 1px solid #1cc91f;border-radius: 20px;" [multiple]='true'
                                                [searchable]="true" formControlName="function" [clearable]="true"
                                                [ngClass]="{'is-invalid': submitted && addUsersFormCnt['function'].errors}">
                                                <ng-option [value]="item.function_name"
                                                    *ngFor="let item of allcategories; let int = index">
                                                    {{item.function_name}}
                                                </ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && addUsersFormCnt['function'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt['function'].errors.required">Please
                                                    Select Preferred Department/Function </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <label>Address <span style="color: red;"> *</span></label>
                                            <textarea id="your_message" class="form-control form-control-custom"
                                                formControlName="address"></textarea>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <div class="gender-area">
                                                    <label>Gender<span style="color: red;"> *</span> </label>
                                                    <div class="form-field">
                                                        <input id="male" type="radio" style="width: 10%;"
                                                            formControlName="gender" value="male">
                                                        <label for="male">Male</label>

                                                        <input id="female" type="radio" style="width: 10%;"
                                                            formControlName="gender" value="female">
                                                        <label for="female">Female</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="create-skills" style="border-radius: 40px;">
                                        <h4 style="text-align: center;">Key Skills</h4>
                                        <form [formGroup]="skillform">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <label>Key Skills <span style="color: red;"> *</span></label>
                                                    <div style="display: flex;">
                                                        <input type="text" style="width: 60%;"
                                                            class="form-control form-control-custom"
                                                            id="validationCustom05" formControlName="skills">
                                                        <a class="create-skills-right1" style="border-radius: 20px;"
                                                            (click)="submitskill()">
                                                            <a type="submit" style="padding: 0px;">Add</a>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                        <div class="row" *ngIf="skillarr.length" style="width: 50%;padding: 20px;">
                                            <table class="table table-image"
                                                style="margin-top: 3%;border: 1px solid black;">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">S.No</th>
                                                        <th scope="col">Skills</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let skill of skillarr; let index = index;">
                                                        <td>{{ index + 1 }}</td>
                                                        <td>{{ skill.skills }}</td>
                                                        <td>
                                                            <button class="btn btn-link text-danger"
                                                                (click)="deleteskillnot(skill.skills)">
                                                                <i class="fa fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 col-lg-12 col-xl-12">
                                            <div class="form-group">
                                                <label for="input-2">Upload Resume <span style="color: red;">
                                                        *</span></label>
                                                <input class="form-control form-control-custom" id="validationCustom05"
                                                    formControlName="resume_pdf" type="file" id="formFile"
                                                    (change)="onFileChange($event)"
                                                    [ngClass]="{'is-invalid': submitted && addUsersFormCnt['resume_pdf'].errors}">
                                                <small>Recruiters give first preference to
                                                    candidates who have a resume.</small>
                                                <div *ngIf="submitted && addUsersFormCnt['resume_pdf'].errors"
                                                    class="invalid-feedback">
                                                    <span *ngIf="addUsersFormCnt['resume_pdf'].errors.required">
                                                        Please Upload Your Resume </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-lg-12 col-xl-12">
                                            <div class="form-group">
                                                <label for="input-2">Upload Profile </label>
                                                <input class="image" class="form-control form-control-custom"
                                                    type="file" id="formFile" (change)="onFileChange1($event)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="center">
                                        <button type="submit" style="border-radius: 35px; padding: 8px 50px;"
                                            class="btn create-ac-btn">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- ///////////////////////////////////////// Mobile Responsive  First///////////////////////////////////////////////// -->
<div class="login-area pt-100 filter" style="padding-top:40px; padding-bottom :60px">
    <div class="container">
        <div class="containers-res" [ngClass]="dynoclass ? 'right-panel-active-res' : '' " id="container">
            <div class="form-container sign-up-container-res">
                <div class="wrapper"
                    style="position:relative; height:540px; width: 100%; overflow-x:hidden; overflow-y:scroll;">
                    <div class="job-card" style="color: #b3b9ba;">
                        <div class="job-inner align-items-center">
                            <div class="row">
                                <div class="col-12 col-lg-10 mx-auto">
                                    <div class="text-start">
                                        <img src="assets/images/Logo (5).png" width="180" alt="">
                                    </div>
                                    <form (ngSubmit)="candidateSignupPost()" [formGroup]="candidateSignup">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <label>Full Name <span style="color: red;"> *</span></label>
                                                <input type="text" class="form-control form-control-custom"
                                                    id="validationCustom05" formControlName="name" required
                                                    [ngClass]="{'is-invalid': submitted && addUsersFormCnt['name'].errors}">

                                                <div *ngIf="submitted && addUsersFormCnt['name'].errors"
                                                    class="invalid-feedback">
                                                    <span *ngIf="addUsersFormCnt['name'].errors.required">Please Enter
                                                        Your Name </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>Email <span style="color: red;"> *</span></label>
                                                <input type="email" class="form-control form-control-custom"
                                                    id="validationCustom05" formControlName="email" required
                                                    [ngClass]="{'is-invalid': submitted && addUsersFormCnt['email'].errors}">
                                                <div *ngIf="submitted && addUsersFormCnt['email'].errors"
                                                    class="invalid-feedback">
                                                    <span *ngIf="addUsersFormCnt['email'].errors.required">Please Enter
                                                        Your Email </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" style="margin-top: 2%;">
                                            <div class="col-lg-6">
                                                <label>Date of Birth <span style="color: red;"> *</span></label>
                                                <input type="date" class="form-control form-control-custom"
                                                    id="validationCustom05" formControlName="date_of_birth" required
                                                    [ngClass]="{'is-invalid': submitted && addUsersFormCnt['date_of_birth'].errors}">
                                                <div *ngIf="submitted && addUsersFormCnt['date_of_birth'].errors"
                                                    class="invalid-feedback">
                                                    <span
                                                        *ngIf="addUsersFormCnt['date_of_birth'].errors.required">Please
                                                        Enter Your
                                                        Date of Birth </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>Mobile Number <span style="color: red;"> *</span></label>
                                                <mat-form-field class="test">
                                                    <!-- <mat-label >PhoneNumber</mat-label> -->
                                                    <ngx-mat-intl-tel-input class="form-control" formControlName="phone"
                                                        class="form-control form-control-custom"
                                                        [enablePlaceholder]=" true " [enableSearch]=" true" name="phone"
                                                        #phone [preferredCountries]="['in']" describedBy="phoneInput">
                                                    </ngx-mat-intl-tel-input>
                                                </mat-form-field>

                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: 2%;">
                                            <!-- <div class="col-lg-6">
                                                <label>Job Title <span style="color: red;"> *</span></label>
                                                <input type="text" class="form-control form-control-custom"
                                                    id="validationCustom05" formControlName="job_title" required
                                                    [ngClass]="{'is-invalid': submitted && addUsersFormCnt['job_title'].errors}">
                                                <div *ngIf="submitted && addUsersFormCnt['job_title'].errors"
                                                    class="invalid-feedback">
                                                    <span *ngIf="addUsersFormCnt['job_title'].errors.required">Please
                                                        Enter Your Job Title </span>
                                                </div>
                                            </div> -->

                                            <div class="col-lg-6">
                                                <label>Job Title <span style="color: red;"> *</span></label>
                                                <br><br>
                                                <select class="form-control form-control-custom" style="width:100%"
                                                    formControlName="job_title"
                                                    [ngClass]="{'is-invalid': submitted && addUsersFormCnt['job_title'].errors}">
                                                    <option value="" disabled selected>Choose Job Title</option>
                                                    <option *ngFor="let p of getDepartment" [value]="p.department_name">
                                                        {{p.department_name}}
                                                    </option>
                                                </select>

                                                <div *ngIf="submitted && addUsersFormCnt['job_title'].errors"
                                                    class="invalid-feedback">
                                                    <span *ngIf="addUsersFormCnt['job_title'].errors.required">Please
                                                        Enter Your Job Title </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>Enter Qualification <span style="color: red;"> *</span></label>
                                                <input type="text" class="form-control form-control-custom"
                                                    id="validationCustom05" formControlName="qulafication" required
                                                    [ngClass]="{'is-invalid': submitted && addUsersFormCnt['qulafication'].errors}">
                                                <div *ngIf="submitted && addUsersFormCnt['qulafication'].errors"
                                                    class="invalid-feedback">
                                                    <span *ngIf="addUsersFormCnt['qulafication'].errors.required">Please
                                                        Enter Your
                                                        Qulafication </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>Year of Passing <span style="color: red;"> *</span></label>
                                                <input type="text" class="form-control form-control-custom"
                                                    id="validationCustom05" formControlName="year_of_passout" required
                                                    [ngClass]="{'is-invalid': submitted && addUsersFormCnt['year_of_passout'].errors}">
                                                <div *ngIf="submitted && addUsersFormCnt['year_of_passout'].errors"
                                                    class="invalid-feedback">
                                                    <span
                                                        *ngIf="addUsersFormCnt['year_of_passout'].errors.required">Please
                                                        Enter Your
                                                        Year of passing </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>Enter percentage <span style="color: red;"> *</span></label>
                                                <input type="text" class="form-control form-control-custom"
                                                    id="validationCustom05" formControlName="percentage" required
                                                    [ngClass]="{'is-invalid': submitted && addUsersFormCnt['percentage'].errors}">
                                                <div *ngIf="submitted && addUsersFormCnt['percentage'].errors"
                                                    class="invalid-feedback">
                                                    <span *ngIf="addUsersFormCnt['city_name'].errors.required">Please
                                                        Enter Your
                                                        percentage </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>Total Experience <span style="color: red;"> *</span></label>
                                                <input type="text" class="form-control form-control-custom"
                                                    id="validationCustom05" formControlName="experience" required
                                                    (keypress)="numericOnly($event)"
                                                    [ngClass]="{'is-invalid': submitted && addUsersFormCnt['experience'].errors}">
                                                <div *ngIf="submitted && addUsersFormCnt['experience'].errors"
                                                    class="invalid-feedback">
                                                    <span *ngIf="addUsersFormCnt['experience'].errors.required">Please
                                                        Enter Your Experience </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>Preferred Industry <span style="color: red;"> *</span></label>
                                                <ng-select class="select custom"
                                                    style="border: 1px solid #1cc91f;border-radius: 20px;"
                                                    [multiple]='true' [searchable]="true" formControlName="industry"
                                                    [clearable]="true"
                                                    [ngClass]="{'is-invalid': submitted && addUsersFormCnt['industry'].errors}">
                                                    <ng-option [value]="item.department_name"
                                                        *ngFor="let item of industry; let int = index">
                                                        {{item.department_name}}
                                                    </ng-option>
                                                </ng-select>
                                                <div *ngIf="submitted && addUsersFormCnt['industry'].errors"
                                                    class="invalid-feedback">
                                                    <span *ngIf="addUsersFormCnt['industry'].errors.required">Please
                                                        Select Preferred Industry</span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>Preferred Department / Function <span style="color: red;">
                                                        *</span></label>
                                                <ng-select class="select custom"
                                                    style="border: 1px solid #1cc91f;border-radius: 20px;"
                                                    [multiple]='true' [searchable]="true" formControlName="function"
                                                    [clearable]="true"
                                                    [ngClass]="{'is-invalid': submitted && addUsersFormCnt['function'].errors}">
                                                    <ng-option [value]="item.function_name"
                                                        *ngFor="let item of allcategories; let int = index">
                                                        {{item.function_name}}
                                                    </ng-option>
                                                </ng-select>
                                                <div *ngIf="submitted && addUsersFormCnt['function'].errors"
                                                    class="invalid-feedback">
                                                    <span *ngIf="addUsersFormCnt['function'].errors.required">Please
                                                        Select Preferred Department/Function </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>Address <span style="color: red;"> *</span></label>
                                                <textarea id="your_message" class="form-control form-control-custom"
                                                    formControlName="address"></textarea>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <div class="gender-area">
                                                        <label>Gender: <span style="color: red;"> *</span></label>
                                                        <div class="form-field">
                                                            <input id="male" type="radio" style="width: 10%;"
                                                                formControlName="gender" value="male">
                                                            <label for="male">Male</label>

                                                            <input id="female" type="radio" style="width: 10%;"
                                                                formControlName="gender" value="female">
                                                            <label for="female">Female</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><br>
                                        <div class="create-skills" style="border-radius: 40px;">
                                            <h3 style="text-align: center;">Key Skills </h3>
                                            <form [formGroup]="skillform">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <label>Key Skills <span style="color: red;"> *</span></label>
                                                        <div style="display: flex;">
                                                            <input type="text" style="width: 65%;"
                                                                class="form-control form-control-custom"
                                                                id="validationCustom05" formControlName="skills"
                                                                required>
                                                            <div class="create-skills-right1"
                                                                style="border-radius: 20px;">
                                                                <a type="submit" (click)="submitskill()"
                                                                    style="padding: 0px;">Add
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div class="row" *ngIf="skillarr.length">
                                                <table class="table table-image" style="margin-top: 3%;">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">S.No</th>
                                                            <th scope="col">Skills</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let p of skillarr ; let i= index;">
                                                            <td>{{i+1}}</td>
                                                            <td>{{p.skills}}</td>
                                                            <td>
                                                                <i class="fa fa-trash"
                                                                    (click)="deleteskillnot(p.skills)"></i>
                                                            </td>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-lg-12 col-xl-12">
                                                <div class="form-group">
                                                    <label for="input-2" style="color: #ff7555;">Upload Resume <span
                                                            style="color: red;"> *</span></label>
                                                    <input class="form-control form-control-custom"
                                                        id="validationCustom05" formControlName="resume_pdf" type="file"
                                                        id="formFile" (change)="onFileChange($event)"
                                                        [ngClass]="{'is-invalid': submitted && addUsersFormCnt['resume_pdf'].errors}">
                                                    <small>Recruiters give first preference to
                                                        candidates who have a resume.</small>
                                                    <div *ngIf="submitted && addUsersFormCnt['resume_pdf'].errors"
                                                        class="invalid-feedback">
                                                        <span *ngIf="addUsersFormCnt['resume_pdf'].errors.required">
                                                            Please Upload Your Resume </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-lg-12 col-xl-12">
                                                <div class="form-group">
                                                    <label for="input-2" style="color: #ff7555;">Upload Profile</label>
                                                    <input class="image" class="form-control form-control-custom"
                                                        type="file" id="formFile" (change)="onFileChange1($event)">
                                                    <!-- <small>Recruiters give first preference to
                                                        candidates who have a resume.</small> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="center">
                                            <button type="submit" style="border-radius: 35px; padding: 8px 50px;"
                                                class="btn create-ac-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="login-sign-in">
                                <ul>
                                    <li>Don’t Have Account ?</li>
                                    <li><a (click)="singup(2)">Sign Up Here</a></li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container sign-in-container-res">
                <div *ngIf="!dynoclass">
                    <div class="wrapper">
                        <div class="authentication-reset-password d-flex align-items-center justify-content-center">
                            <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s">
                                <div class="job-card" style="color: #b3b9ba;">
                                    <div class="job-inner align-items-center">
                                        <div class="row">
                                            <div class="col-12 col-lg-10 mx-auto">
                                                <div class="text-start">
                                                    <img src="assets/images/Logo (5).png" width="180" alt="">
                                                </div>
                                                <h5 class="font-weight-bold">Login as Job seeker With OTP</h5>
                                                <!-- <p class="text-muted"> Please Enter Mobile Number!</p> -->
                                                <form [formGroup]="loginstudentForm" (ngSubmit)="studentSubmit()">
                                                    <div class="mb-3" *ngIf="!otpget">
                                                        <div class="position-relative has-icon-left">

                                                            <div class="input-group" id="show_hide_password">

                                                                <mat-form-field>
                                                                    <label>Phone Number</label>

                                                                    <ngx-mat-intl-tel-input class="form-control"
                                                                        formControlName="phone"
                                                                        [enablePlaceholder]=" true "
                                                                        class="form-control form-control-custom"
                                                                        [enableSearch]=" true" name="phone" #phone
                                                                        [preferredCountries]="['in']"
                                                                        describedBy="phoneInput">
                                                                    </ngx-mat-intl-tel-input>
                                                                </mat-form-field>

                                                            </div>
                                                            <div *ngIf="submittedstudent && f1['phone'].errors"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.phone.errors.required"> Please Enter
                                                                    Mobile
                                                                    Number</div>
                                                                <!-- <div *ngIf="f.email.errors.email">Email must be a valid email address</div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mb-3" *ngIf="otpget">
                                                        <div class="position-relative has-icon-left">
                                                            <label for="password" class="form-label">OTP</label>
                                                            <div class="input-group" id="show_hide_password">
                                                                <input type="text" class="form-control border-end-0"
                                                                    formControlName="usr_pwd" id="password"
                                                                    class="form-control" placeholder="Enter OTP"
                                                                    (keypress)="numericOnly($event)"
                                                                    (paste)="$event.preventDefault()"
                                                                    [ngClass]="{ 'is-invalid': submittedstudent && f1['usr_pwd'].errors }">
                                                                <a href="javascript:;"
                                                                    class="input-group-text bg-transparent"><span
                                                                        class="material-icons">lock</span></a>
                                                            </div>
                                                            <div *ngIf="submittedstudent && f1['usr_pwd'].errors"
                                                                class="invalid-feedback">
                                                                <span *ngIf="f['usr_pwd'].errors.required">Please Enter
                                                                    OTP</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="cust-resend" *ngIf="otpget">
                                                        <div class="icheck-material-primary" (click)="getOtpstudent()">
                                                            <span class="resend" *ngIf="refresh">
                                                                <span class="material-icons cust-icon"> replay_30
                                                                </span>
                                                                <span>Resend</span>
                                                            </span>
                                                            <span *ngIf="(timer$ | async) as timer">Re Send OTP in
                                                                <span>{{timer}} Sec </span> </span>
                                                        </div>
                                                        <div class="icheck-material-primary"
                                                            (click)="studentResetpassword2()">
                                                            <a href="JavaScript:;">Wrong Number</a>
                                                        </div>
                                                    </div>
                                                    <div class="d-grid gap-2">
                                                        <button *ngIf="!otpget" type="button" (click)="getOtpstudent()"
                                                            class="btn btn-primary btn-block waves-effect waves-light log-btn curse"
                                                            [disabled]="submittedstudent">Request OTP </button>
                                                        <button *ngIf="otpget" type="submit"
                                                            class="btn btn-primary     btn-block waves-effect waves-light  log-btn curse">
                                                            Submit OTP </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="login-sign-in">
                                            <ul>
                                                <li>Don’t Have Account ?</li>
                                                <li><a (click)="dynoclass = true;studentResetpassword2()  "
                                                        id="signUp">Sign Up</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlay-container-res">
                <div class="overlay-res">
                    <div class="overlay-panel-res overlay-left-res">
                        <h1>Welcome Back!</h1>
                        <p>Already have an account?</p>
                        <p>Please Login here</p>
                        <button type="button" class="ghost" (click)="dynoclass = false; onResetpassword2() "
                            id="signIn">Sign In</button>
                    </div>
                    <div class="overlay-panel-new overlay-right-res">
                        <h1>Welcome Back!</h1>
                        <p>Are you new here?</p>
                        <p>Please Sign up as a Job Seeker</p>
                        <button type="button" class="ghost" (click)="dynoclass = true;studentResetpassword2()  "
                            id="signUp">Sign Up</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ///////////////////////////////////////// Mobile Responsive  End///////////////////////////////////////////////// -->


<div class="my-overlay" *ngIf="showOverlay">
    <mat-spinner></mat-spinner>
    <h5 style="color: white;">Please Wait...</h5>
</div>